import React, { Component } from 'react';
import backgroundImage from './assets/websitebackground.webp';
import backgroundImageMobile from './assets/websitebackgroundvertical.webp';

class WebsiteContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opacity: 1,
      isMobile: window.innerWidth <= 768, // Detect if the screen width is mobile
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ opacity: 0.95 });
    }, 1);

    // Add event listener for window resize to handle dynamic resizing
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobile: window.innerWidth <= 768 });
  };

  render() {
    const { opacity, isMobile } = this.state;

    const containerStyle = {
      position: 'relative',
      minHeight: '100vh',
      width: '100vw',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'auto',
    };

    const backgroundStyle = {
      position: 'fixed',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      backgroundImage: `url(${isMobile ? backgroundImageMobile : backgroundImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      opacity: opacity,
      filter: 'brightness(80%)',
      transition: 'opacity 1s ease-in-out',
      zIndex: -1,
    };

    const contentStyle = {
      position: 'relative',
      zIndex: 1,
      padding: '20px',
    };

    return (
      <div style={containerStyle}>
        <div style={backgroundStyle}></div>
        <div style={contentStyle}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default WebsiteContainer;