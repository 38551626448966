const REMOTE_API_URL = process.env.REACT_APP_API_BASE_URL; // Loaded from .env or hosting environment
const LOCAL_API_URL = "http://localhost:5001";

export const getApiBaseURL = () => {
  const currentUrl = window.location.href;

  const isProd = localStorage.getItem('isProd') === 'true';

  if (currentUrl.includes('localhost')) {
    return isProd ? REMOTE_API_URL : LOCAL_API_URL;
  } else if (currentUrl.includes('theloveguru.ai')) {
    return REMOTE_API_URL;
  }

  throw new Error('Unknown environment: Unable to determine API base URL');
};
