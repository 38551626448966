import React, { Component } from 'react';
import { AppBar, Toolbar, Button, IconButton, Box, Menu, MenuItem } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import HomeIcon from '@mui/icons-material/Home'; 
import MenuIcon from '@mui/icons-material/Menu'; // Menu icon for mobile view
import { connect } from 'react-redux';
import { logOut } from '../reducer/user'; 
import { Link } from 'react-router-dom';
import { persistor } from '../reducer/store'; 
import Logo from './assets/iconnew.png';
import LogoGreen from './assets/iconnewgreen.png';
import LogoYellow from './assets/iconnewyellow.png';
import GreenDot from './assets/greendot.gif';
import YellowDot from './assets/yellowdot.gif';

const headerSx = {
  backgroundColor: 'black',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1000,
  display: 'flex',
  justifyContent: 'center',
};

const centerTextSx = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)', // Centers content horizontally and vertically
  fontSize: '10px',
  color: 'white',
  textAlign: 'center',
  '@media (max-width: 600px)': {
    fontSize: '10px',
  },
};


const buttonSx = {
  color: 'white',
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  fontSize: '10px',
  '@media (max-width: 700px)': {
    fontSize: '10px',
    minWidth: 'auto',
    padding: '0 8px',
  },
};

const iconButtonSx = {
  color: 'white',
  '@media (max-width: 600px)': {
    padding: '0 13px',
  },
};

const menuSx = {
  '& .MuiPaper-root': {
    backgroundColor: 'black', 
    color: 'white',
    border: '1px solid white',
    borderRadius: '5px',
  },
};

const menuItemSx = {
  color: 'white',
  fontWeight: 'bold', 
  fontSize: '10px', 
  textTransform: 'uppercase', 
  padding: '10px 25px',
  '&:hover': {
    backgroundColor: '#444',
  }
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenuIcon: false,
      anchorEl: null,
      mobileMenuAnchorEl: null,
      isGreen: false,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();

    this.logoInterval = setInterval(() => {
      this.setState((prevState) => ({ isGreen: !prevState.isGreen }));
    }, 2000);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);

    if (this.logoInterval) {
      clearInterval(this.logoInterval);
    }
  }
  
  handleResize = () => {
    const width = window.innerWidth;
    this.setState((prevState) => ({
      showMenuIcon: width < 800,
      // Automatically close the mobile menu if resizing occurs
      mobileMenuAnchorEl: width >= 800 ? null : prevState.mobileMenuAnchorEl,
    }));
  };
  

  handleLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userId');
    this.props.logOut();
    persistor.purge();
    window.location.href = '/login';
  };

  handleMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleMobileMenuOpen = (event) => {
    this.setState({ mobileMenuAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMenuAnchorEl: null });
  };

  render() {
    const { anchorEl, mobileMenuAnchorEl, showMenuIcon, isGreen } = this.state;
    const { userData, isLoggedIn } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMenuAnchorEl);

    return (
      <AppBar position="static" sx={headerSx}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '96%', paddingRight: '15px' }}>
          <IconButton
            sx={iconButtonSx}
            component={Link}
            to="/" 
          >
            <HomeIcon />
          </IconButton>

          <Box sx={centerTextSx}>
            <Button sx={buttonSx} component={Link} to="/">
              <img
                src={Logo}
                alt="White Logo"
                style={{
                  width: '40px',
                  marginRight: '150px',
                  position: 'absolute',
                  opacity: !isGreen || !userData?.isActive ? 1 : 0,
                  transition: 'opacity 1s ease-in-out',
                }}
              />
              {userData?.email?.length > 0 && (
                <img
                  src={userData?.isActive && !userData?.shouldPause ? LogoGreen : LogoYellow}
                  alt={userData?.isActive && !userData?.shouldPause ? "Green Logo" : "Yellow Logo"}
                  style={{
                    width: '40px',
                    marginRight: '150px',
                    position: 'absolute',
                    opacity: isGreen ? 1 : 0,
                    transition: 'opacity 1s ease-in-out',
                  }}
                />
              )}
              The love guru AI
            </Button>
          </Box>


          {showMenuIcon ? (
            <>
              <IconButton
                sx={iconButtonSx}
                onClick={this.handleMobileMenuOpen}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={mobileMenuAnchorEl}
                open={isMobileMenuOpen}
                onClose={this.handleMobileMenuClose}
                sx={menuSx}
              >
                {userData && isLoggedIn ? (
                  <>
                    <MenuItem sx={menuItemSx} component={Link} to="/usersettings" onClick={this.handleMobileMenuClose}>
                      My Settings
                      <img 
                        src={userData?.isActive && !userData?.shouldPause ? GreenDot : YellowDot} 
                        alt={userData?.isActive && !userData?.shouldPause ? "Online Status" : "Offline Status"} 
                        style={{ width: '12px', height: '12px', marginLeft: '8px' }} 
                      />
                    </MenuItem>
                    <MenuItem sx={menuItemSx} component={Link} to="/mysubscription" onClick={this.handleMobileMenuClose}>
                      My Subscription
                    </MenuItem>
                    <MenuItem sx={menuItemSx} component={Link} to="/mysubscription/upgrade" onClick={this.handleMobileMenuClose}>
                      Upgrade Subscription
                    </MenuItem>
                    <MenuItem sx={menuItemSx} component={Link} to="/about" onClick={this.handleMobileMenuClose}>
                      About
                    </MenuItem>
                    <MenuItem sx={menuItemSx} component={Link} to="/contact" onClick={this.handleMobileMenuClose}>
                      Contact Us
                    </MenuItem>
                    <MenuItem sx={menuItemSx} onClick={this.handleLogout}>
                      Logout
                    </MenuItem>
                  </>
                ) : (
                  <>
                    <MenuItem sx={menuItemSx} component={Link} to="/login" onClick={this.handleMobileMenuClose}>
                      Login
                    </MenuItem>
                    <MenuItem sx={menuItemSx} component={Link} to="/howitworks" onClick={this.handleMobileMenuClose}>
                      How It Works
                    </MenuItem>
                    <MenuItem sx={menuItemSx} component={Link} to="/about" onClick={this.handleMobileMenuClose}>
                      About
                    </MenuItem>
                    <MenuItem sx={menuItemSx} component={Link} to="/contact" onClick={this.handleMobileMenuClose}>
                      Contact Us
                    </MenuItem>
                  </>
                )}
              </Menu>
            </>
          ) : (
            <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
              {userData && isLoggedIn ? (
                <>
                  <Button sx={buttonSx} component={Link} to="/usersettings">
                    <img 
                      src={userData?.isActive && !userData?.shouldPause ? GreenDot : YellowDot} 
                      alt={userData?.isActive && !userData?.shouldPause ? "Online Status" : "Offline Status"} 
                      style={{ width: '12px', height: '12px', marginRight: '8px' }} 
                    />
                    My Settings
                  </Button>
                  <Button sx={buttonSx} component={Link} to="/about">About</Button>
                  <Button sx={buttonSx} component={Link} to="/contact">Contact Us</Button>
                  <Button sx={buttonSx} onClick={this.handleMenuOpen}>
                    My Account
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={isMenuOpen}
                    onClose={this.handleMenuClose}
                    sx={menuSx}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    slotProps={{
                      paper: { style: { marginTop: '18px' } },
                    }}
                  >
                    <MenuItem 
                      onClick={this.handleMenuClose} 
                      component={Link} to="/mysubscription" sx={menuItemSx}
                    >
                      My Subscription
                    </MenuItem>
                    <MenuItem 
                      onClick={this.handleMenuClose} 
                      component={Link} to="/mysubscription/upgrade" sx={menuItemSx}
                    >
                      Upgrade Subscription
                    </MenuItem>
                    <MenuItem onClick={this.handleLogout} sx={menuItemSx}>
                      Logout
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <>
                  <Button sx={buttonSx} component={Link} to="/login">Login</Button>
                  <Button sx={buttonSx} component={Link} to="/howitworks">How It Works</Button>
                  <Button sx={buttonSx} component={Link} to="/about">About</Button>
                  <Button sx={buttonSx} component={Link} to="/contact">Contact Us</Button>
                </>
              )}
              <IconButton
                sx={iconButtonSx}
                href="https://www.instagram.com/hinge_ai_assistant/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon />
              </IconButton>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.userData,
  isLoggedIn: state.user.isLoggedIn,
});

const mapDispatchToProps = {
  logOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
